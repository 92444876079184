.preview_slip {
  &__content {
    margin: 3rem;
    width: 14.8cm;
    height: auto;

    position: absolute;
    left: -30px;
    background-position-x: left;

    page-break-after: always;
    page-break-inside: avoid;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  &__header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;

    &--left {
      font-size: 12px;
      font-weight: bold;
      align-items: flex-start;
    }

    &--right {
      font-size: 12px;
      font-weight: bold;
      align-items: flex-end;
    }
  }

  &__header-title {
    margin-top: $gutter-small;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;

    &--column {
      flex-direction: column;
      text-align: center;

      &--bold {
        font-weight: bold;
      }
    }
  }

  &__table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      color: $color-black;
    }

    tr td,
    tr th {
      &:nth-child(1) {
        width: 1%;
      }
    }

    td.batas {
      margin-top: 4rem;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }

    td.judul {
      font-size: 12px;
      white-space: nowrap;
    }

    td.titik {
      font-size: 12px;
      width: 1px;
    }

    td {
      font-size: 12px;
    }

    tr.batas {
      height: 1rem;
    }

    th {
      font-size: 12px;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 2rem;
    margin-right: 2rem;

    &--left {
      font-size: 12px;
      font-weight: bold;
      align-items: flex-start;
    }

    &--right {
      font-size: 12px;
      font-weight: bold;
      align-items: flex-end;
    }

    ol {
      width: 100%;
      padding-left: 20px;
      list-style-type: lower-alpha;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;

    &--left {
      font-size: 12px;
      font-weight: bold;
      align-items: flex-end;
      margin-right: 20px;

      &--inside{
        margin-right: 5px;
        text-decoration: underline;
        text-align: right;
      }
    }

    &--right {
      font-size: 12px;
      font-weight: bold;
      align-items: center;
    }
  }
}
