@import url('https://fonts.googleapis.com/css?family=Muli|Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  height: 100%;
  width: 100%;

  @media only screen and (max-width: $bp-large) {
    font-size: 50%;
  }
}

body {
  font-family: 'Muli', 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-black;
  background-color: $color-background;
  min-height: 100%;
}

button {
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;

  &:active,
  &:focus {
    outline: none;
  }
}

h1, h2, h3 {
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

p {
  font-size: $font-size-default;
}

table {
  thead {
    th {
      background: #909090;
      color: #FFFFFF;
    }
  }

  .total {
    td {
      background: #909090;
      font-weight:bold;
      color: #FFFFFF;
    }
  }
}

.align-center {
  text-align: center;
}

.buttons-wrapper {
  display: flex;
  margin-top: 20px;
  & > div{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    &:last-child {

    }
  }
}

.hidden {
  display: none;
}

.workflow_remarks{
  width: 100%;
  white-space: pre-wrap;
  margin-bottom: 20px;
  font-size: 14px;
}

.workflow_history {
  width: 100%;
  .table td:nth-child(2) {
    width: 30%;
  }
}

.print {
  position: absolute;
  right: 2.75rem;
  top: 2.25rem;
}
